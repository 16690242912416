import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from 'components/layout';
import Img from 'gatsby-image';
import { LoadingClass, Quick } from 'utils/common';
import { Helmet } from 'react-helmet';
import CloseBtnSvg from '../img/close_btn.svg';
import ArrowSvg from '../img/arrow_short.svg';
import Slider from 'react-slick';

const Loading = new LoadingClass();

export default class WorkTemplate extends React.Component {
	componentDidMount() {
		Loading.dataLoaded = true;
		Loading.Load();
		Loading.PostLoaded = () => {
			document.body.classList.remove('fromwork');
			Quick.qS('#WorksBg').classList.remove('on');
		};
		EachWorkFunction.init();
		document.body.addEventListener('transitionexit', this.transitionExit, false);

		if (typeof document !== `undefined`) document.body.id = 'work';
	}
	transitionExit() {
		setTimeout(() => {
			Loading.ReloadPost();
		}, 1000);
	}
	componentDidUpdate() {
		EachWorkFunction.init();
	}
	componentWillUnmount() {
		Loading.Exit();
	}
	render() {
		Loading.MinTimeLoad();

		const content = this.props.data.content;
		const seo = this.props.data.content.frontmatter.seo;
		const works = this.props.data.works;

		let tagyear = false;
		if (content.frontmatter.tag || content.frontmatter.year) tagyear = true;
		const next_slug = this.props.pageContext.next_slug;
		const next_title = this.props.pageContext.next_title;

		const sliderSettings = {
			dots: false,
			infinite: false,
			speed: 500,
			autoplay: false,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 750,
					settings: {
						unslick: true
					}
				}
			],
			beforeChange: (current, next) => {
				EachWorkFunction.CheckSlide(next, content);
			}
		};

		return (
			<Layout titleText={content.frontmatter.title} mainId="work">
				<Helmet>
					{seo.seo_shortdesc && <meta name="description" content={seo.seo_shortdesc} />}
					{seo.seo_image && <meta name="image" content={seo.seo_image} />}
					{seo.seo_keywords && <meta name="keywords" content={seo.seo_keywords} />}
					{seo.seo_shortdesc && <meta property="og:description" content={seo.seo_shortdesc} />}
					{seo.seo_image && <meta property="og:image" content={seo.seo_image} />}
					{seo.seo_shortdesc && <meta name="twitter:description" content={seo.seo_shortdesc} />}
					{seo.seo_image && <meta name="twitter:image" content={seo.seo_image} />}
				</Helmet>
				<section className="projectinfo full">
					<div>
						<span>
							<h1>{content.frontmatter.title}</h1>
						</span>
						<p>{content.frontmatter.description}</p>
						<div>
							{tagyear && <span className="dash">&mdash;</span>}
							{content.frontmatter.tag && <span className="tag">{content.frontmatter.tag}</span>}
							{content.frontmatter.year && <span className="year">{content.frontmatter.year}</span>}
						</div>
					</div>
				</section>
				<section id="ImageSliders" className="fitheight">
					<div>
						<Link id="CloseButton" to={`/${works.frontmatter.slug}`}>
							<img src={CloseBtnSvg} alt="MALT Studio Button" />

							<span>
								<span>back to</span>
							</span>
							<span>WORKS</span>
						</Link>
						<span
							id="ViewMore"
							onClick={() => Quick.qS('main#work').classList.add('slideropen')}
							onMouseEnter={EachWorkFunction.triggerViewHover}
						>
							<span>view more &rsaquo;&rsaquo;</span>
						</span>
						<span
							id="Desc"
							onClick={() => Quick.qS('main#work').classList.remove('slideropen')}
							onMouseEnter={EachWorkFunction.triggerDescHover}
						>
							<span>&lsaquo;&lsaquo; desc.</span>
						</span>
						{next_slug !== null && (
							<Link
								id="NextButton"
								to={`/${next_slug}`}
								className={content.frontmatter.imagelist.length === 1 ? `active` : null}
							>
								<span>
									<span>next work</span>
								</span>
								<span>{next_title}</span>
							</Link>
						)}
					</div>
					<div id="SliderWrapper">
						{content.frontmatter.imagelist.length > 1 && (
							<div id="SliderNavigation">
								<div
									id="NavPrev"
									className="hide"
									onClick={() => {
										this.slider.slickPrev();
									}}
								>
									<img src={ArrowSvg} alt="MALT Studio Arrow" />
								</div>
								<div>
									{content.frontmatter.imagelist.map((node, id) => {
										return (
											<div
												className={id === 1 ? 'active' : ``}
												key={id}
												onClick={() => {
													this.slider.slickGoTo(id);
													EachWorkFunction.setNav(id);
												}}
											/>
										);
									})}
								</div>
								<div
									id="NavNext"
									onClick={() => {
										this.slider.slickNext();
									}}
								>
									>
									<img src={ArrowSvg} alt="MALT Studio Arrow" />
								</div>
							</div>
						)}
						{content.frontmatter.imagelist.length > 1 ? (
							<Slider {...sliderSettings} ref={(c) => (this.slider = c)} id="SliderContainer">
								{content.frontmatter.imagelist.map((node, id) => {
									return (
										<div className="eachimage" key={id}>
											<Img fluid={node.image.childImageSharp.fluid} alt={node.title} />
										</div>
									);
								})}
							</Slider>
						) : (
							<div id="SliderContainer">
								<div className="eachimage">
									<Img
										fluid={content.frontmatter.imagelist[0].image.childImageSharp.fluid}
										alt={content.frontmatter.imagelist[0].title}
									/>
								</div>
							</div>
						)}
					</div>
				</section>
			</Layout>
		);
	}
}

const EachWorkFunction = {
	init: () => {
		const MainWork = Quick.qS('main#work');
		if (MainWork.classList.contains('slideropen')) {
			MainWork.classList.remove('slideropen');
		}
		if (Quick.qS('#NavPrev')) Quick.qS('#NavPrev').classList.add('hide');

		EachWorkFunction.setNav(0);
		// Slider.addEventListener('click', EachWorkFunction.toggleImageSliders, { passive: true });
		// ViewMore.addEventListener('click', EachWorkFunction.toggleImageViewMore, { passive: true });
	},
	clear: () => {
		const MainWork = Quick.qS('main#work');
		if (MainWork && MainWork.classList.contains('slideropen')) {
			MainWork.classList.remove('slideropen');
		}
		if (Quick.qS('#NavPrev')) Quick.qS('#NavPrev').classList.add('hide');
	},
	setNav: (active) => {
		const AllNav = Quick.qSAll('div#SliderNavigation >  div:nth-child(2) > div ');
		if (AllNav.length >= 1) {
			AllNav.forEach((nav) => {
				nav.classList.remove('active');
			});
			AllNav[active].classList.add('active');
		}
	},
	CheckSlide: (current, content) => {
		if (current === 0) {
			if (Quick.qS('#NavPrev')) Quick.qS('#NavPrev').classList.add('hide');
		} else {
			if (Quick.qS('#NavPrev')) Quick.qS('#NavPrev').classList.remove('hide');
		}
		if (current === content.frontmatter.imagelist.length - 1) {
			Quick.qS('#NextButton').classList.add('active');
			if (Quick.qS('#NavNext')) Quick.qS('#NavNext').classList.add('hide');
		} else {
			if (Quick.qS('#NavNext')) Quick.qS('#NavNext').classList.remove('hide');
			if (Quick.qS('#NextButton')) Quick.qS('#NextButton').classList.remove('active');
		}
		EachWorkFunction.setNav(current);
	},
	descHovering: false,
	descTimer: null,
	triggerDescHover: () => {
		if (!('ontouchstart' in document.documentElement)) {
			const descelem = Quick.qS('span#Desc');
			if (!EachWorkFunction.descHovering) {
				EachWorkFunction.EachdescHovering = true;
				descelem.classList.remove('hovering');
				clearTimeout(EachWorkFunction.descTimer);
				EachWorkFunction.descTimer = null;
				descelem.classList.add('hovering');
				EachWorkFunction.descTimer = setTimeout(() => {
					EachWorkFunction.EachdescHovering = false;
					clearTimeout(EachWorkFunction.descTimer);
					EachWorkFunction.descTimer = null;
					descelem.classList.remove('hovering');
				}, 600);
			}
		}
	},
	viewHovering: false,
	viewTimer: null,
	triggerViewHover: (e) => {
		if (!('ontouchstart' in document.documentElement)) {
			const descelem = Quick.qS('span#ViewMore');
			if (!EachWorkFunction.descHovering) {
				EachWorkFunction.EachdescHovering = true;
				descelem.classList.remove('hovering');
				clearTimeout(EachWorkFunction.descTimer);
				EachWorkFunction.descTimer = null;
				descelem.classList.add('hovering');
				EachWorkFunction.descTimer = setTimeout(() => {
					EachWorkFunction.EachdescHovering = false;
					clearTimeout(EachWorkFunction.descTimer);
					EachWorkFunction.descTimer = null;
					descelem.classList.remove('hovering');
				}, 600);
			}
		}
	}
};

export const query = graphql`
	query($slug: String!) {
		all: allMarkdownRemark(
			filter: { frontmatter: { issetting: { eq: false }, contenttype: { eq: "work" } } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			totalCount
			edges {
				node {
					id
					frontmatter {
						title
					}
					fields {
						slug
					}
				}
			}
		}
		works: markdownRemark(frontmatter: { issetting: { eq: false }, contenttype: { eq: "works" } }) {
			frontmatter {
				title
				slug
			}
		}
		content: markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				tag
				year
				description
				seo {
					seo_shortdesc
					seo_keywords
					seo_image {
						childImageSharp {
							fluid(maxWidth: 1200) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
				imagelist {
					title
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
			}
		}
	}
`;
